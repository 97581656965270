@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    /* padding-top: var(--topbarHeightDesktop); */
    /* min-height: calc(100vh - var(--topbarHeightDesktop)); */
  }
  @media(max-width:320px){
    display: block;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 18px 0 0 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.9%;
    padding: 0px 0 0 0;
  }
}
.noSearchResults {
  color: var(--marketplaceColorNoResult);
}
.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    /* margin-bottom: 21px; */
    align-items: center;
    margin: 10px 0 ;
    width: 100%;
    max-width: 90%;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
  @media (max-width: 991px) {
    max-width: 85%;
    /* overflow-x:auto; */
  }
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
  @media (max-width: 420px) {
    padding: 16px 0 16px 24px;
  }
  @media(max-width:320px){
    padding:16px 15px;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  & > div {
    & > div {
      & > div {
        & > div {
          & > div:nth-child(2) {
            min-height: 160px;
            @media (max-width:1199px) {
              min-height: 230px;
            }
            @media(max-width:991px) {
              min-height: 160px;
            }
            @media(max-width:767px){
              min-height: 160px;
            }
            & > div{
              & > div:nth-child(1){
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
  & > div {
    & > div {
      min-height: 260px;
      @media (max-width: 767px) {
        min-height: 495px;
        /* margin-left: auto;
        margin-right: auto; */
      }
      @media (max-width: 575px) {
        min-height: 400px;
      }

      & > div {
        gap:7px;
       
        & > div:nth-child(2) {
             
          & > div {
            & > div:nth-child(2) {
              & > p {
                min-height: 30px;
                margin:0;
              }
            }
          }
          & > div:nth-child(2) {
            margin-top: 4px;
          }
        }
        @media(max-width:575px){
          gap: 0 10px;
        }
      }
      & > div:nth-child(2){
        margin-bottom:10px;
      }
    }
  }
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    /* display: flex; */
    height: 100%;
    display: block !important;
    position: sticky;
    /* top: 185px; */
    top:155px;
  }

  @media (--viewportLarge) {
    flex-basis: 54%;
  }
}

.sortBy {
  margin-right: 9px;
  @media (max-width: 575px) {
    margin-right: 0;
  }
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;
  padding: 10px;
  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    /* position: fixed; */
    /* top: var(--topbarHeightDesktop); */
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    /* width: 37.5vw; */
    width: 46vw;
  }
  /* @media(max-width:768px){
    position: fixed;
    right: 0;
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  } */
}

.searchFilterSection {
  padding-top: var(--topbarHeightDesktop);
  border-bottom: 4px solid var(--marketplaceColorBorder);
  min-height: 86px;
  position: sticky;
  z-index: 2;
  background: white;
  top: 0;
  display: flex;
  /* overflow-x: auto; */
  @media (max-width: 767px) {
    padding-top: 0;
    padding-left: 20px;
    background: transparent;
    position: relative;
    display: none;
  }
}

.filterLabel {
  /* background: #fff2f1;
  border: 1px solid #fe5f50;
  border-radius: 20px; */
  min-width: 129px;
  margin: 0 5px;
  & button {
    &:hover {
      box-shadow: none;
    }
  }
}

.clearFilter {
  text-decoration-line: underline;
  font-size: 14px;
  font-weight: 400;
  color: #939291;
  width: 120px;
  white-space: nowrap;
  padding-right: 36px;
  flex-basis: 10%;
  @media (max-width: 1199px) {
    padding-right: 0;
  }
}

.loaderContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 100%;
  }
}
